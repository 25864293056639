<template>
  <div class="flex-content">
    <!-- 头部 -->
    <div class="section-header flex-alignc-justifyb pdl-0 pdb-0">
      <div>角色</div>
      <a-button @click="handleCreate">添加角色</a-button>
    </div>
    <div class="flex-main pdl-0 pdb-0">
      <div class="bgfff h100 pd-20 flex-directionc">
        <div class="mb-20">
          角色用来控制成员对对象（如电站）的数据查看范围及所能使用的功能。设置完成后请在【部门/成员】添加新成员和编辑成员中分配角色。
        </div>
        <div class="flex-sub1" ref="yHeight">
          <!-- 表格 -->
          <myTable
            :columns="columns"
            :dataSource="tableData"
            :scroll="{ x: true, y: yHeight }"
            :total="total"
            @changePagination="getTableList"
          >
            <template slot="operationSlot" slot-scope="slotData">
              <div class="flex-alignc-justifya operation-box">
                <!-- <a-icon type="copy" title="复制" /> -->
                <a-icon
                  type="edit"
                  title="编辑"
                  @click="handleCreate(slotData.scope)"
                />
                <a-icon
                  type="delete"
                  title="删除"
                  @click="handleDel(slotData.scope)"
                />
              </div>
            </template>
          </myTable>
        </div>
      </div>
    </div>

    <!-- 添加新角色弹窗 -->
    <a-drawer
      :bodyStyle="{
        padding: '0.3rem 0 0 0',
      }"
      :closable="false"
      :destroyOnClose="true"
      :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
      }"
      :visible="visible"
      @close="closeTopDrawer"
      height="100%"
      placement="top"
      wrapClassName="create-power"
    >
      <template #title>
        <div class="head-box">
          <span>添加新角色</span>
          <div class="head-btn">
            <a-button @click="closeTopDrawer">取消</a-button>
            <a-button class="save-btn" type="primary" @click="handleSubmit"
              >保存</a-button
            >
          </div>
        </div>
      </template>
      <div class="content-box">
        <div class="flex h100" style="width: 80%; margin: auto">
          <a-form-model
            style="width: 100%"
            ref="form"
            :model="form"
            :rules="rules"
          >
            <a-form-model-item label="角色名称" prop="roleName">
              <a-input v-model="form.roleName" placeholder="角色名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="角色描述">
              <a-input v-model="form.roleDesc" placeholder="角色描述"></a-input>
            </a-form-model-item>
            <a-form-model-item label="可见视图">
              <!-- <a-checkbox-group v-model="form.view">
                <a-checkbox v-for="(item,index) in options" :key="index" :value="item">{{item.label}}</a-checkbox>
              </a-checkbox-group>
              <div class="menu-list">
                <span :class="menuIndex==-1?'on':''" @click="changeMenu(-1)">全局</span>
                <span :class="menuIndex==index?'on':''" v-for="(item,index) in form.value1" :key="index" @click="changeMenu(index)">{{ item.label }}</span>
              </div> -->

              <a-table
                :columns="menuColumns"
                :dataSource="menuList"
                bordered
                :scroll="{ x: true, y: 400 }"
                :pagination="false"
                size="middle"
              >
                <div slot="menu" slot-scope="scope">
                  <a-checkbox
                    :indeterminate="scope.indeterminate"
                    :checked="scope.checkAll"
                    @change="onCheckAllChange($event, scope)"
                  >
                    {{ scope.menuName }}
                  </a-checkbox>
                </div>
                <div slot="function" slot-scope="scope">
                  <a-checkbox-group
                    v-model="scope.checkList"
                    @change="onMenuChange($event, scope)"
                  >
                    <a-checkbox
                      v-for="(item, index) in scope.menuEntities"
                      :key="index"
                      :value="item.id"
                      >{{ item.menuName }}</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
                <div slot="data" slot-scope="scope">
                  <!-- <a-radio-group v-model="scope.data" @change="onChange">
                    <a-radio v-for="(item,index) in scope.dataList" :key="index" :value="item.id" :style="{display: 'block'}">
                      {{item.name}}
                    </a-radio>
                  </a-radio-group> -->
                </div>
              </a-table>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getRoleList,
  getRoleDetail,
  createRole,
  delRole,
  getMenuList,
} from "@/api/role";
import { debound } from "@/utils/index.js";
export default {
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 70,
          customRender: (text, record, index) => {
            return index + 1;
          },
        },
        {
          title: "角色名称",
          dataIndex: "roleName",
          width: 120,
        },
        {
          title: "角色描述",
          dataIndex: "roleDesc",
        },
        {
          title: "操作",
          width: 120,
          scopedSlots: { customRender: "operationSlot" },
        },
      ],
      tableData: [],
      yHeight:
        document.documentElement.clientHeight -
        document.documentElement.clientHeight / 4.5,
      total: 0,

      visible: false,
      form: {
        roleName: "角色名称1",
        roleDesc: "角色描述",
        view: [], // 可见视图
        menuEntities: [], // 选择的菜单数据
      },
      rules: {
        roleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "suiyidingyi",
          },
        ],
      },
      options: [
        { label: "监控", value: "1" },
        { label: "分析", value: "2" },
        { label: "运维", value: "3" },
        { label: "应用", value: "4" },
        { label: "管理", value: "5" },
      ], // 可见视图数据
      menuIndex: -1,

      menuColumns: [
        {
          title: "对象",
          key: "menu",
          width: 120,
          scopedSlots: { customRender: "menu" },
        },
        {
          title: "功能权限",
          key: "function",
          scopedSlots: { customRender: "function" },
        },
      ], // 表格表头数据
      menuList: [
        // 菜单列表数据
        // {
        //   id:1,
        //   name:'电站',
        //   indeterminate:false, // 半选状态
        //   checkAll: false, // 全选状态
        //   checkList:[], // 多选数据
        //   menuList:[
        //     {name:'查看子系统/设备',id:'2'},
        //     {name:'添加子系统/设备',id:'3'},
        //   ],
        // data:'1', // 1 本人的电站 2 本人所在部门的电站 3 本人所在部门及下级部门的电站 4 全部电站
        // dataList:[
        //   {name:'本人的电站',id:'1'},
        //   {name:'本人所在部门的电站',id:'2'},
        //   {name:'本人所在部门及下级部门的电站',id:'3'},
        //   {name:'全部电站',id:'4'},
        // ]
        // },
      ],
    };
  },
  created() {
    this.getTableList();
  },
  mounted() {
    this.watchResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.screenResize);
  },
  methods: {
    watchResize() {
      this.yHeight = this.$refs["yHeight"].clientHeight - 100;
      window.addEventListener("resize", this.screenResize);
    },
    screenResize: debound(function () {
      // document.documentElement.clientHeight;
      this.yHeight = this.$refs["yHeight"].clientHeight - 100;
    }, 200),
    // 获取表格数据
    getTableList(pageMsg) {
      let requestPage = {
        page: 1,
        limit: 50,
      };
      if (pageMsg?.page) {
        requestPage.page = pageMsg.page;
        requestPage.limit = pageMsg.pageSize;
      }
      getRoleList(requestPage).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
      });
    },
    // 删除角色
    handleDel(item) {
      let that = this;
      this.$confirm({
        title: "确认删除该角色吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          delRole({
            id: item.id,
          }).then((res) => {
            that.getTableList();
          });
        },
      });
    },

    // 添加/编辑角色
    handleCreate(item) {
      this.visible = true;
      this.getMenuList(item.id ? item.id : "");
    },
    // 获取角色详情
    getDetail(id) {
      getRoleDetail({
        id,
      }).then((res) => {
        res.data.menuEntities = this.transTree(res.data.menuEntities);
        this.menuList.forEach((item) => {
          res.data.menuEntities.forEach((item1) => {
            if (item.id == item1.id) {
              item.checkList = item1.menuEntities.map((item3) => {
                return item3.id;
              });
              this.onMenuChange(item.checkList, item);
            }
          });
        });
        this.form = res.data;
        console.log(this.menuList);
      });
    },

    transTree(souceData) {
      const targetData = [];
      // 以每一项的id作为key,自身作为value形成对象结构
      const map = {};
      souceData.forEach((item) => {
        map[item.id] = item;
        item.menuEntities = [];
      });
      // 遍历数组通过parentId去匹配id,匹配到就添加到chilren属性中,匹配不到代表自身就是最外层的父节点,添加到最终的数组中
      souceData.forEach((item) => {
        if (map[item.parentId]) {
          // 匹配上
          map[item.parentId].menuEntities.push(item);
        } else {
          // 没有匹配上
          targetData.push(item);
        }
      });
      // 返回的是处理之后的数组
      return targetData;
    },
    // 抽屉关闭
    closeTopDrawer() {
      this.visible = false;
      this.form = {
        id: "",
        roleName: "",
        roleDesc: "",
        view: [], // 可见视图
        menuEntities: [], // 选择的菜单数据
      };
      this.menuList = [];
    },
    // 获取权限列表
    getMenuList(id) {
      getMenuList().then((res) => {
        res.data.forEach((item) => {
          item.indeterminate = false;
          item.checkAll = false;
          item.checkList = [];
        });
        this.menuList = res.data;
        if (id) {
          this.getDetail(id);
        }
      });
    },
    // 监听视图选择
    changeMenu(index) {
      this.menuIndex = index;
    },
    // 监听菜单对象选择
    onCheckAllChange(e, item) {
      item.checkList = e.target.checked
        ? item.menuEntities.map((item) => {
            return item.id;
          })
        : [];
      item.indeterminate = false;
      item.checkAll = e.target.checked;
    },
    // 监听菜单功能选择
    onMenuChange(checkList, item) {
      item.indeterminate =
        !!checkList.length && checkList.length < item.menuEntities.length;
      item.checkAll = checkList.length === item.menuEntities.length;
    },
    handleSubmit() {
      this.menuList.forEach((item) => {
        let ids = [];
        if (item.checkAll) {
          ids = item.menuEntities.map((item1) => {
            return { id: item1.id };
          });
          // ids.push({ id: item.id });
          this.form.menuEntities = [...this.form.menuEntities, ...ids];
          // debugger;
        } else if (item.indeterminate) {
          ids = item.checkList.map((item1) => {
            return { id: item1 };
          });
          // ids.push({ id: item.id });
          this.form.menuEntities = [...this.form.menuEntities, ...ids];
        }
      });
      console.log(this.form.menuEntities);
      this.$refs.form.validate((valid) => {
        if (valid) {
          createRole(
            this.form.id ? "updateSingle" : "saveSingle",
            this.form
          ).then((res) => {
            this.getTableList();
            this.closeTopDrawer();
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.menu-list {
  span {
    cursor: pointer;
    margin-right: 20px;
    &.on {
      color: #048fff;
    }
  }
}
/deep/.ant-table {
  .operation-box {
    font-size: 16px;
  }
}
</style>